import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getOrder = createAsyncThunk('appOrder/getOrder', async () => {
  const response = await axios.get('/api/projects')

  return response.data.data
})

export const getBranches = createAsyncThunk('appOrder/getBranches', async ({ vendorId, projectId}) => {
  const result = await axios.get(`/api/vendors/${vendorId}/interfaces/roofhub/branches/nearest/${projectId}`)

  return result.data
})

export const appLeadsSlice = createSlice({
  name: 'appOrder',
  initialState: {
    params: {
      branch: null,
      shipTo: null,
      expectedDeliveryDate: null,
      expectedDeliveryTime: null,
      shippingMethod: null
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBranches.fulfilled, (state, action) => {
        state.branches = action.payload
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.branches = action.payload
      })
  }
})

export default appLeadsSlice.reducer