// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const searchbarSlice = createSlice({
  name: 'searchbar',
  initialState: {
    query: '',
    onSubmitSearch: (query) => { console.log(query) }
  },
  reducers: {
    handleSearchQuery: (state, action) => {
        state.query = action.payload
    },
    setOnSubmitSearch: (state, action) => {
        state.onSubmitSearch = action.payload
    }
  }
})

export const { handleSearchQuery, setOnSubmitSearch } = searchbarSlice.actions

export default searchbarSlice.reducer
