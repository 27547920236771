// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import axios from 'axios'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const fetchCurrentUser = createAsyncThunk('authentication/fetchCurrentUser', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get('/api/users/current')

    if (response.status === 200) {
      return response.data
    } else {
      return rejectWithValue('Failed to fetch current user')
    }
  } catch (error) {
    console.error('Failed to fetch current user', error)
    return rejectWithValue(error.response?.data || 'Failed to fetch current user')
  }
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    isLoading: false,
    error: null
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload }
      localStorage.setItem('userData', JSON.stringify(state.userData))
    },
    handleLogin: (state, action) => {
      state.userData = { ...state.userData, ...action.payload.userData }
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]

      localStorage.setItem('userData', JSON.stringify(action.payload.userData))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
      localStorage.setItem(config.storageTenantName, action.payload.tenant)
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.setItem(config.storageTenantName)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.userData = {
            ...state.userData,
            firstName: action.payload.given_name,
            lastName: action.payload.family_name,
            fullName: action.payload.name,
            email: action.payload.email,
            phoneNumber: action.payload.phone_number,
            picture: action.payload.picture
        }
        localStorage.setItem('userData', JSON.stringify(state.userData))
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
  }
})

export const { handleLogin, handleLogout, setUserData } = authSlice.actions

export default authSlice.reducer
